.App {
    height: 100vh;
    text-align: center;
    background-color: #FF5F45;

}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


.Absolute-Center {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.text-center {
    align-content: center;
}

.text-font {
    font-family: 'Titillium Web', sans-serif
}

.Absolute-Center.is-Responsive {
    width: 80%;
    height: 60%;
    min-width: 200px;
    /*max-width: 600px;*/
    padding: 40px;
    background-color: #2D3246;
    border-radius: 5px;
    /*box-shadow: 10px 10px #25304E*/
    -webkit-box-shadow: 0px 0px 35px 11px rgba(0, 0, 0, 0.40);
    -moz-box-shadow: 0px 0px 35px 11px rgba(0, 0, 0, 0.40);
    box-shadow: 0px 0px 35px 11px rgba(0, 0, 0, 0.40);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
